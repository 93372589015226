import { IconSVGProps } from '@/components/ui/icon'

function CheckCircle({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 14 14"
      width={size}
      fill="currentcolor"
    >
      <path
        d="M6.99984 1.16663C3.77984 1.16663 1.1665 3.77996 1.1665 6.99996C1.1665 10.22 3.77984 12.8333 6.99984 12.8333C10.2198 12.8333 12.8332 10.22 12.8332 6.99996C12.8332 3.77996 10.2198 1.16663 6.99984 1.16663ZM5.419 9.50246L3.32484 7.40829C3.09734 7.18079 3.09734 6.81329 3.32484 6.58579C3.55234 6.35829 3.91984 6.35829 4.14734 6.58579L5.83317 8.26579L9.8465 4.25246C10.074 4.02496 10.4415 4.02496 10.669 4.25246C10.8965 4.47996 10.8965 4.84746 10.669 5.07496L6.2415 9.50246C6.01984 9.72996 5.6465 9.72996 5.419 9.50246Z"
        fill="#28A745"
      />
    </svg>
  )
}

export default CheckCircle
