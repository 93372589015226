import { forwardRef } from 'react'

import * as A from '@/components/icons'
import { cn } from '@/lib/utils'

export type IconSVGProps = {
  size?: number
  width?: number
  height?: number
}

export const icons = {
  home: ({ size }: IconSVGProps) => <A.Home size={size} />,
  today: ({ size }: IconSVGProps) => <A.Today size={size} />,
  lightbulb: ({ size }: IconSVGProps) => <A.LightBulb size={size} />,
  assignment: ({ size }: IconSVGProps) => <A.Assignment size={size} />,
  assignment_ind: ({ size }: IconSVGProps) => <A.AssignmentInd size={size} />,
  phone_iphone: ({ size }: IconSVGProps) => <A.PhoneIphone size={size} />,
  security: ({ size }: IconSVGProps) => <A.Security size={size} />,
  paid: ({ size }: IconSVGProps) => <A.Paid size={size} />,
  menu: ({ size }: IconSVGProps) => <A.Menu size={size} />,
  more_horiz: ({ size }: IconSVGProps) => <A.MoreHorizontal size={size} />,
  login: ({ size }: IconSVGProps) => <A.Login size={size} />,
  logout: ({ size }: IconSVGProps) => <A.Logout size={size} />,
  menu_open: ({ size }: IconSVGProps) => <A.MenuOpen size={size} />,
  modal_delete_icon: ({ size }: IconSVGProps) => <A.ModalDeleteIcon size={size} />,
  chevron_left: ({ size }: IconSVGProps) => <A.ChevronLeft size={size} />,
  chevron_right: ({ size }: IconSVGProps) => <A.ChevronRight size={size} />,
  calendar_today: ({ size }: IconSVGProps) => <A.CalendarToday size={size} />,
  description: ({ size }: IconSVGProps) => <A.Description size={size} />,
  x: ({ size }: IconSVGProps) => <A.X size={size} />,
  person: ({ size }: IconSVGProps) => <A.Person size={size} />,
  radio_button_checked: ({ size }: IconSVGProps) => <A.RadioButtonChecked size={size} />,
  check: ({ size }: IconSVGProps) => <A.Check size={size} />,
  mail: ({ size }: IconSVGProps) => <A.Mail size={size} />,
  info: ({ size }: IconSVGProps) => <A.Info size={size} />,
  store: ({ size }: IconSVGProps) => <A.Store size={size} />,
  badge: ({ size }: IconSVGProps) => <A.Badge size={size} />,
  bookmark: ({ size }: IconSVGProps) => <A.Bookmark size={size} />,
  location_on: ({ size }: IconSVGProps) => <A.LocationOn size={size} />,
  link: ({ size }: IconSVGProps) => <A.Link size={size} />,
  play_circle: ({ size }: IconSVGProps) => <A.PlayCircle size={size} />,
  pause_circle: ({ size }: IconSVGProps) => <A.PauseCircle size={size} />,
  flag_circle: ({ size }: IconSVGProps) => <A.FlagCircle size={size} />,
  stacked_line_chart: ({ size }: IconSVGProps) => <A.StackedLineChart size={size} />,
  web: ({ size }: IconSVGProps) => <A.Web size={size} />,
  lock_person: ({ size }: IconSVGProps) => <A.LockPerson size={size} />,
  lock: ({ size }: IconSVGProps) => <A.Lock size={size} />,
  delete: ({ size }: IconSVGProps) => <A.Delete size={size} />,
  download: ({ size }: IconSVGProps) => <A.Download size={size} />,
  pencil: ({ size }: IconSVGProps) => <A.Pencil size={size} />,
  visibility: ({ size }: IconSVGProps) => <A.Visibility size={size} />,
  visibility_off: ({ size }: IconSVGProps) => <A.VisibilityOff size={size} />,
  local_activity: ({ size }: IconSVGProps) => <A.LocalActivity size={size} />,
  local_play: ({ size }: IconSVGProps) => <A.LocalPlay size={size} />,
  search: ({ size }: IconSVGProps) => <A.Search size={size} />,
  add: ({ size }: IconSVGProps) => <A.Add size={size} />,
  filter: ({ size }: IconSVGProps) => <A.Filter size={size} />,
  circle: ({ size }: IconSVGProps) => <A.Circle size={size} />,
  star: ({ size }: IconSVGProps) => <A.Star size={size} />,
  co_present: ({ size }: IconSVGProps) => <A.CoPresent size={size} />,
  content_copy: ({ size }: IconSVGProps) => <A.ContentCopy size={size} />,
  format_bold: ({ size }: IconSVGProps) => <A.FormatBold size={size} />,
  format_italic: ({ size }: IconSVGProps) => <A.FormatItalic size={size} />,
  format_underline: ({ size }: IconSVGProps) => <A.FormatUnderline size={size} />,
  format_strikethrough: ({ size }: IconSVGProps) => <A.FormatStrikethrough size={size} />,
  format_align_center: ({ size }: IconSVGProps) => <A.FormatAlignCenter size={size} />,
  format_align_left: ({ size }: IconSVGProps) => <A.FormatAlignLeft size={size} />,
  format_list_bulleted: ({ size }: IconSVGProps) => <A.FormatListBulleted size={size} />,
  format_list_numbered: ({ size }: IconSVGProps) => <A.FormatListNumbered size={size} />,
  code: ({ size }: IconSVGProps) => <A.Code size={size} />,
  undo: ({ size }: IconSVGProps) => <A.Undo size={size} />,
  redo: ({ size }: IconSVGProps) => <A.Redo size={size} />,
  rocket_launch: ({ size }: IconSVGProps) => <A.RocketLaunch size={size} />,
  lowercase: ({ size }: IconSVGProps) => <A.Lowercase size={size} />,
  uppercase: ({ size }: IconSVGProps) => <A.Uppercase size={size} />,
  titlecase: ({ size }: IconSVGProps) => <A.Titlecase size={size} />,
  'peaple-group': ({ size }: IconSVGProps) => <A.PeapleGroup size={size} />,
  satisfied: ({ size }: IconSVGProps) => <A.Satisfied size={size} />,
  unsatisfied: ({ size }: IconSVGProps) => <A.Unsatisfied size={size} />,
  'large-logo': ({ width, height }: IconSVGProps) => (
    <A.LargeDarkLogo height={height} width={width} />
  ),
  'small-logo': ({ width, height }: IconSVGProps) => (
    <A.SmallDarkLogo height={height} width={width} />
  ),
  ticket: ({ size }: IconSVGProps) => <A.Ticket size={size} />,
  calendar: ({ size }: IconSVGProps) => <A.Calendar size={size} />,
  location: ({ size }: IconSVGProps) => <A.Location size={size} />,
  world: ({ size }: IconSVGProps) => <A.World size={size} />,
  'check-file': ({ size }: IconSVGProps) => <A.CheckFile size={size} />,
  medal: ({ size }: IconSVGProps) => <A.Medal size={size} />,
  number: ({ size }: IconSVGProps) => <A.Number size={size} />,
  city: ({ size }: IconSVGProps) => <A.City size={size} />,
  place: ({ size }: IconSVGProps) => <A.Place size={size} />,
  phone: ({ size }: IconSVGProps) => <A.Phone size={size} />,
  cellphone: ({ size }: IconSVGProps) => <A.Cellphone size={size} />,
  label: ({ size }: IconSVGProps) => <A.Label size={size} />,
  'attach-file': ({ size }: IconSVGProps) => <A.AttachFile size={size} />,
  destination: ({ size }: IconSVGProps) => <A.Destination size={size} />,
  image: ({ size }: IconSVGProps) => <A.Image size={size} />,
  close: ({ size }: IconSVGProps) => <A.Close size={size} />,
  'lock-reset': ({ size }: IconSVGProps) => <A.LockReset size={size} />,
  'attach-email': ({ size }: IconSVGProps) => <A.AttachEmail size={size} />,
  'location-searching': ({ size }: IconSVGProps) => <A.LocationSearching size={size} />,
  more: ({ size }: IconSVGProps) => <A.More size={size} />,
  contactless: ({ size }: IconSVGProps) => <A.Contactless size={size} />,
  'vpn-lock': ({ size }: IconSVGProps) => <A.VpnLock size={size} />,
  hub: ({ size }: IconSVGProps) => <A.Hub size={size} />,
  warning: ({ size }: IconSVGProps) => <A.Warning size={size} />,
  dns: ({ size }: IconSVGProps) => <A.Dns size={size} />,
  attach_money: ({ size }: IconSVGProps) => <A.AttachMoney size={size} />,
  access_time: ({ size }: IconSVGProps) => <A.AccessTime size={size} />,
  'keyboard-arrow-up': ({ size }: IconSVGProps) => <A.KeyboardArrowUp size={size} />,
  'keyboard-arrow-down': ({ size }: IconSVGProps) => <A.KeyboardArrowDown size={size} />,
  language: ({ size }: IconSVGProps) => <A.Language size={size} />,
  'keyboard-arrow-right': ({ size }: IconSVGProps) => (
    <A.KeyboardArrowRight size={size} />
  ),
  'additional-field': ({ size }: IconSVGProps) => <A.AdditionalField size={size} />,
  'add-circle': ({ size }: IconSVGProps) => <A.AddCircle size={size} />,
  'video-cam': ({ size }: IconSVGProps) => <A.VideoCam size={size} />,
  public: ({ size }: IconSVGProps) => <A.Public size={size} />,
  file: ({ size }: IconSVGProps) => <A.File size={size} />,
  'spread-sheet': ({ size }: IconSVGProps) => <A.SpreadSheet size={size} />,
  audio: ({ size }: IconSVGProps) => <A.Audio size={size} />,
  format_h1: ({ size }: IconSVGProps) => <A.FormatH1 size={size} />,
  format_h2: ({ size }: IconSVGProps) => <A.FormatH2 size={size} />,
  format_h3: ({ size }: IconSVGProps) => <A.FormatH3 size={size} />,
  format_h_options: ({ size }: IconSVGProps) => <A.FormatHOptions size={size} />,
  format_text_options: ({ size }: IconSVGProps) => <A.FormatTextOptions size={size} />,
  format_align_justify: ({ size }: IconSVGProps) => <A.FormatAlignJustify size={size} />,
  format_align_right: ({ size }: IconSVGProps) => <A.FormatAlignRight size={size} />,
  slides: ({ size }: IconSVGProps) => <A.Slides size={size} />,
  translate: ({ size }: IconSVGProps) => <A.Translate size={size} />,
  pix: ({ size }: IconSVGProps) => <A.Pix size={size} />,
  punch_card: ({ size }: IconSVGProps) => <A.PunchCard size={size} />,
  wallet: ({ size }: IconSVGProps) => <A.Wallet size={size} />,
  credit_card: ({ size }: IconSVGProps) => <A.CreditCard size={size} />,
  request_page: ({ size }: IconSVGProps) => <A.RequestPage size={size} />,
  subject: ({ size }: IconSVGProps) => <A.Subject size={size} />,
  brain: ({ size }: IconSVGProps) => <A.Brain size={size} />,
  globe: ({ size }: IconSVGProps) => <A.Globe size={size} />,
  scanner: ({ size }: IconSVGProps) => <A.Scanner size={size} />,
  app_registration: ({ size }: IconSVGProps) => <A.AppRegistration size={size} />,
  foundation: ({ size }: IconSVGProps) => <A.Foundation size={size} />,
  brain_network: ({ size }: IconSVGProps) => <A.BrainNetwork size={size} />,
  chart: ({ size }: IconSVGProps) => <A.Chart size={size} />,
  block: ({ size }: IconSVGProps) => <A.Block size={size} />,
  website: ({ size }: IconSVGProps) => <A.Website size={size} />,
  savings: ({ size }: IconSVGProps) => <A.Savings size={size} />,
  check_circle: ({ size }: IconSVGProps) => <A.CheckCircle size={size} />,
  dangerous: ({ size }: IconSVGProps) => <A.Dangerous size={size} />,
  radio_button_unchecked: ({ size }: IconSVGProps) => <A.RadioButton size={size} />,
  'account-balance': ({ size }: IconSVGProps) => <A.AccountBalance size={size} />,
  'pie-chart': ({ size }: IconSVGProps) => <A.Piechart size={size} />,
  'person-search': ({ size }: IconSVGProps) => <A.PersonSearch size={size} />,
  'manage-account': ({ size }: IconSVGProps) => <A.ManageAccount size={size} />,
  history: ({ size }: IconSVGProps) => <A.History size={size} />
}

export type IconsType = keyof typeof icons

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  name: IconsType
  size?: number
  width?: number
  height?: number
}

const Icon = forwardRef<HTMLDivElement, IconProps>(
  ({ name, className, size = 24, ...props }, ref) => {
    return (
      <div
        className={cn(
          `w-[${size}px] h-[${size}px]`,
          'flex items-center justify-center',
          className
        )}
        ref={ref}
        {...props}
      >
        {icons[name]({ size, ...props })}
      </div>
    )
  }
)

Icon.displayName = 'Icon'

export { Icon }
