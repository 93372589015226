import { IconSVGProps } from '@/components/ui/icon'

function History({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.81896 1.75008C4.84979 1.66842 2.41729 4.05425 2.41729 7.00008H1.37313C1.11063 7.00008 0.982295 7.31508 1.16896 7.49592L2.79646 9.12925C2.91313 9.24592 3.09396 9.24592 3.21063 9.12925L4.83813 7.49592C5.01896 7.31508 4.89063 7.00008 4.62813 7.00008H3.58396C3.58396 4.72508 5.43896 2.88758 7.72563 2.91675C9.89563 2.94592 11.7215 4.77175 11.7506 6.94175C11.7798 9.22258 9.94229 11.0834 7.66729 11.0834C6.72813 11.0834 5.85896 10.7626 5.17063 10.2201C4.93729 10.0393 4.61063 10.0568 4.40063 10.2668C4.15563 10.5118 4.17313 10.9259 4.44729 11.1359C5.33396 11.8359 6.44813 12.2501 7.66729 12.2501C10.6131 12.2501 12.999 9.81758 12.9173 6.84842C12.8415 4.11258 10.5548 1.82592 7.81896 1.75008ZM7.52146 4.66675C7.28229 4.66675 7.08396 4.86508 7.08396 5.10425V7.25092C7.08396 7.45508 7.19479 7.64758 7.36979 7.75258L9.18979 8.83175C9.39979 8.95425 9.66813 8.88425 9.79063 8.68008C9.91313 8.47008 9.84313 8.20175 9.63896 8.07925L7.95896 7.08175V5.09842C7.95896 4.86508 7.76063 4.66675 7.52146 4.66675Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default History
