import { IconSVGProps } from '@/components/ui/icon'

function PersonSearch({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66016 8.40234C9.31701 8.40234 10.6602 7.0592 10.6602 5.40234C10.6602 3.74549 9.31701 2.40234 7.66016 2.40234C6.0033 2.40234 4.66016 3.74549 4.66016 5.40234C4.66016 7.0592 6.0033 8.40234 7.66016 8.40234Z"
        fill="currentcolor"
      />
      <path
        d="M7.92266 9.90984C5.87516 9.83484 1.66016 10.8548 1.66016 12.9023V13.6523C1.66016 14.0648 1.99766 14.4023 2.41016 14.4023H8.81516C6.96266 12.3323 7.89266 9.98484 7.92266 9.90984Z"
        fill="currentcolor"
      />
      <path
        d="M14.7327 12.9173C15.0852 12.3173 15.2577 11.5898 15.0927 10.8023C14.8377 9.57234 13.8027 8.58984 12.5577 8.43234C10.5852 8.17734 8.92016 9.83484 9.18266 11.8073C9.34766 13.0523 10.3227 14.0873 11.5527 14.3423C12.3402 14.5073 13.0677 14.3348 13.6677 13.9823L15.0627 15.3773C15.3552 15.6698 15.8277 15.6698 16.1202 15.3773C16.4127 15.0848 16.4127 14.6123 16.1202 14.3198L14.7327 12.9173ZM12.1602 12.9023C11.3352 12.9023 10.6602 12.2273 10.6602 11.4023C10.6602 10.5773 11.3352 9.90234 12.1602 9.90234C12.9852 9.90234 13.6602 10.5773 13.6602 11.4023C13.6602 12.2273 12.9852 12.9023 12.1602 12.9023Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default PersonSearch
