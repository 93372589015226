import { IconSVGProps } from '@/components/ui/icon'

function ManageAccount({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.61529 9.39391C8.45029 9.38641 8.28529 9.37891 8.11279 9.37891C6.29779 9.37891 4.60279 9.88141 3.15529 10.7439C2.49529 11.1339 2.11279 11.8689 2.11279 12.6414V13.8789C2.11279 14.2914 2.45029 14.6289 2.86279 14.6289H9.05779C8.46529 13.7814 8.11279 12.7464 8.11279 11.6289C8.11279 10.8264 8.30029 10.0764 8.61529 9.39391Z"
        fill="currentcolor"
      />
      <path
        d="M8.11279 8.62891C9.76965 8.62891 11.1128 7.28576 11.1128 5.62891C11.1128 3.97205 9.76965 2.62891 8.11279 2.62891C6.45594 2.62891 5.11279 3.97205 5.11279 5.62891C5.11279 7.28576 6.45594 8.62891 8.11279 8.62891Z"
        fill="currentcolor"
      />
      <path
        d="M16.1753 11.6289C16.1753 11.4639 16.1528 11.3139 16.1303 11.1564L16.7603 10.6089C16.8953 10.4889 16.9253 10.2939 16.8353 10.1364L16.3928 9.37141C16.3028 9.21391 16.1153 9.14641 15.9503 9.20641L15.1553 9.47641C14.9153 9.27391 14.6453 9.11641 14.3453 9.00391L14.1803 8.18641C14.1428 8.01391 13.9928 7.88641 13.8128 7.88641H12.9278C12.7478 7.88641 12.5978 8.01391 12.5603 8.18641L12.3953 9.00391C12.0953 9.11641 11.8253 9.27391 11.5853 9.47641L10.7903 9.20641C10.6178 9.14641 10.4378 9.22141 10.3478 9.37141L9.90529 10.1364C9.81529 10.2939 9.84529 10.4889 9.98029 10.6089L10.6103 11.1564C10.5878 11.3139 10.5653 11.4639 10.5653 11.6289C10.5653 11.7939 10.5878 11.9439 10.6103 12.1014L9.98029 12.6489C9.84529 12.7689 9.81529 12.9639 9.90529 13.1214L10.3478 13.8864C10.4378 14.0439 10.6253 14.1114 10.7903 14.0514L11.5853 13.7814C11.8253 13.9839 12.0953 14.1414 12.3953 14.2539L12.5603 15.0714C12.5978 15.2439 12.7478 15.3714 12.9278 15.3714H13.8128C13.9928 15.3714 14.1428 15.2439 14.1803 15.0714L14.3453 14.2539C14.6453 14.1414 14.9153 13.9839 15.1553 13.7814L15.9503 14.0514C16.1228 14.1114 16.3028 14.0364 16.3928 13.8864L16.8353 13.1214C16.9253 12.9639 16.8953 12.7689 16.7603 12.6489L16.1303 12.1014C16.1528 11.9439 16.1753 11.7939 16.1753 11.6289ZM13.3628 13.1289C12.5378 13.1289 11.8628 12.4539 11.8628 11.6289C11.8628 10.8039 12.5378 10.1289 13.3628 10.1289C14.1878 10.1289 14.8628 10.8039 14.8628 11.6289C14.8628 12.4539 14.1878 13.1289 13.3628 13.1289Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default ManageAccount
