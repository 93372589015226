import { IconSVGProps } from '@/components/ui/icon'

function Dangerous({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 14 14"
      width={size}
      fill="currentcolor"
    >
      <path
        d="M8.69167 1.75H5.30833C4.99917 1.75 4.70167 1.8725 4.48 2.09417L2.08833 4.48583C1.8725 4.70167 1.75 4.99917 1.75 5.30833V8.69167C1.75 9.00083 1.8725 9.29833 2.09417 9.51417L4.48583 11.9058C4.70167 12.1275 4.99917 12.25 5.30833 12.25H8.69167C9.00083 12.25 9.29833 12.1275 9.51417 11.9058L11.9058 9.51417C12.1275 9.29833 12.25 9.00083 12.25 8.69167V5.30833C12.25 4.99917 12.1275 4.70167 11.9058 4.48583L9.51417 2.09417C9.29833 1.8725 9.00083 1.75 8.69167 1.75ZM9.065 9.065C8.8375 9.2925 8.47 9.2925 8.2425 9.065L7 7.8225L5.76333 9.05917C5.53583 9.28667 5.16833 9.28667 4.94083 9.05917C4.71333 8.83167 4.71333 8.46417 4.94083 8.23667L6.1775 7L4.935 5.76333C4.7075 5.53583 4.7075 5.16833 4.935 4.94083C5.1625 4.71333 5.53 4.71333 5.7575 4.94083L7 6.1775L8.23667 4.94083C8.46417 4.71333 8.83167 4.71333 9.05917 4.94083C9.28667 5.16833 9.28667 5.53583 9.05917 5.76333L7.8225 7L9.05917 8.23667C9.2925 8.46417 9.2925 8.8375 9.065 9.065Z"
        fill="#DC3545"
      />
    </svg>
  )
}

export default Dangerous
