import { IconSVGProps } from '@/components/ui/icon'

function RadioButton({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 14 14"
      width={size}
      fill="currentcolor"
    >
      <path
        d="M6.99984 1.16663C3.77984 1.16663 1.1665 3.77996 1.1665 6.99996C1.1665 10.22 3.77984 12.8333 6.99984 12.8333C10.2198 12.8333 12.8332 10.22 12.8332 6.99996C12.8332 3.77996 10.2198 1.16663 6.99984 1.16663ZM6.99984 11.6666C4.4215 11.6666 2.33317 9.57829 2.33317 6.99996C2.33317 4.42163 4.4215 2.33329 6.99984 2.33329C9.57817 2.33329 11.6665 4.42163 11.6665 6.99996C11.6665 9.57829 9.57817 11.6666 6.99984 11.6666Z"
        fill="#94979C"
      />
    </svg>
  )
}

export default RadioButton
