import { IconSVGProps } from '@/components/ui/icon'

function PieChart({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.81201 2.38417V15.6142C8.81201 16.0942 8.36951 16.4542 7.90451 16.3492C4.55201 15.5992 2.06201 12.5917 2.06201 8.99917C2.06201 5.40667 4.55201 2.39917 7.90451 1.64917C8.36951 1.54417 8.81201 1.90417 8.81201 2.38417ZM10.3345 2.38417V7.49167C10.3345 7.90417 10.672 8.24167 11.0845 8.24167H16.177C16.657 8.24167 17.017 7.79917 16.912 7.32667C16.2745 4.50667 14.062 2.28667 11.2495 1.64917C10.777 1.54417 10.3345 1.90417 10.3345 2.38417ZM10.3345 10.5067V15.6142C10.3345 16.0942 10.777 16.4542 11.2495 16.3492C14.0695 15.7117 16.282 13.4842 16.9195 10.6642C17.0245 10.1992 16.657 9.74917 16.1845 9.74917H11.092C10.672 9.75667 10.3345 10.0942 10.3345 10.5067Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default PieChart
