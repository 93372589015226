import { IconSVGProps } from '@/components/ui/icon'

function AccountBalance({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 12V6C7.125 5.175 7.7925 4.5 8.625 4.5H15.375V3.75C15.375 2.925 14.7 2.25 13.875 2.25H3.375C2.5425 2.25 1.875 2.925 1.875 3.75V14.25C1.875 15.075 2.5425 15.75 3.375 15.75H13.875C14.7 15.75 15.375 15.075 15.375 14.25V13.5H8.625C7.7925 13.5 7.125 12.825 7.125 12ZM9.375 6C8.9625 6 8.625 6.3375 8.625 6.75V11.25C8.625 11.6625 8.9625 12 9.375 12H16.125V6H9.375ZM11.625 10.125C11.0025 10.125 10.5 9.6225 10.5 9C10.5 8.3775 11.0025 7.875 11.625 7.875C12.2475 7.875 12.75 8.3775 12.75 9C12.75 9.6225 12.2475 10.125 11.625 10.125Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default AccountBalance
