import { IconSVGProps } from '@/components/ui/icon'

function LocalPlay({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6C14 5.43 14.3225 4.935 14.795 4.68C15.245 4.4325 15.5 3.9225 15.5 3.405V1.5C15.5 0.675 14.825 0 14 0H2C1.175 0 0.5075 0.6675 0.5075 1.4925V3.405C0.5075 3.9225 0.755 4.4325 1.2125 4.6725C1.685 4.935 2 5.43 2 6C2 6.57 1.6775 7.0725 1.205 7.32C0.755 7.5675 0.5 8.0775 0.5 8.595V10.2825C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V8.595C15.5 8.0775 15.245 7.5675 14.795 7.32C14.3225 7.065 14 6.57 14 6ZM9.875 9.075L8 7.875L6.125 9.0825C5.84 9.2625 5.4725 9 5.5625 8.67L6.125 6.51L4.4 5.1C4.1375 4.8825 4.2725 4.455 4.6175 4.4325L6.8375 4.305L7.6475 2.2425C7.775 1.9275 8.225 1.9275 8.345 2.2425L9.155 4.3125L11.375 4.44C11.7125 4.4625 11.855 4.89 11.5925 5.1075L9.8675 6.5175L10.4375 8.6625C10.5275 9 10.16 9.2625 9.875 9.075Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default LocalPlay
