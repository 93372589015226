import { IconSVGProps } from '@/components/ui/icon'

function Today({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 2H11.5V1.25C11.5 0.8375 11.1625 0.5 10.75 0.5C10.3375 0.5 10 0.8375 10 1.25V2H4V1.25C4 0.8375 3.6625 0.5 3.25 0.5C2.8375 0.5 2.5 0.8375 2.5 1.25V2H1.75C0.9175 2 0.2575 2.675 0.2575 3.5L0.25 14C0.25 14.825 0.9175 15.5 1.75 15.5H12.25C13.075 15.5 13.75 14.825 13.75 14V3.5C13.75 2.675 13.075 2 12.25 2ZM11.5 14H2.5C2.0875 14 1.75 13.6625 1.75 13.25V5.75H12.25V13.25C12.25 13.6625 11.9125 14 11.5 14ZM4 7.25H6.25C6.6625 7.25 7 7.5875 7 8V10.25C7 10.6625 6.6625 11 6.25 11H4C3.5875 11 3.25 10.6625 3.25 10.25V8C3.25 7.5875 3.5875 7.25 4 7.25Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Today
