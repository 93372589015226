import { IconSVGProps } from '@/components/ui/icon'

function Savings({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2207 5.33327L11.7073 3.81994C11.754 3.53994 11.8273 3.27994 11.9207 3.05327C11.994 2.87994 12.0207 2.67994 11.9807 2.47327C11.894 1.99327 11.4273 1.65994 10.934 1.6666C9.87398 1.6866 8.93398 2.2066 8.33398 2.99994H5.00065C2.97398 2.99994 1.33398 4.63994 1.33398 6.6666C1.33398 8.1666 2.24732 11.6533 2.72065 13.3599C2.88065 13.9333 3.40732 14.3333 4.00732 14.3333H5.33398C6.06732 14.3333 6.66732 13.7333 6.66732 12.9999H8.00065C8.00065 13.7333 8.60065 14.3333 9.33398 14.3333H10.674C11.2607 14.3333 11.7807 13.9466 11.954 13.3799L12.7873 10.6066L14.214 10.1266C14.4873 10.0333 14.6673 9.77993 14.6673 9.49327V5.99994C14.6673 5.63327 14.3673 5.33327 14.0007 5.33327H13.2207ZM8.00065 6.33327H6.00065C5.63398 6.33327 5.33398 6.03327 5.33398 5.6666C5.33398 5.29994 5.63398 4.99994 6.00065 4.99994H8.00065C8.36732 4.99994 8.66732 5.29994 8.66732 5.6666C8.66732 6.03327 8.36732 6.33327 8.00065 6.33327ZM10.6673 7.6666C10.3007 7.6666 10.0007 7.3666 10.0007 6.99994C10.0007 6.63327 10.3007 6.33327 10.6673 6.33327C11.034 6.33327 11.334 6.63327 11.334 6.99994C11.334 7.3666 11.034 7.6666 10.6673 7.6666Z"
        fill="#6B6E75"
      />
    </svg>
  )
}

export default Savings
